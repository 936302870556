<template>
  <div class="flex flex-col w-full h-full min-h-full">
    <AppHeader></AppHeader>
    <div class="flex flex-grow overflow-hidden">
      <AppAside />
      <div class="flex flex-col w-full h-full bg-secondary relative">
        <div class="h-full overflow-y-auto bg-primary-body">
          <slot></slot>
        </div>
      </div>
    </div>
    <filter-panel v-if="isOpenFilterPanel" :panelOpen="isOpenFilterPanel"></filter-panel>
  </div>
</template>

<script>
import AppHeader from '@/components/core/AppHeader.vue'
import AppAside from '@/components/core/AppAside.vue'
import FilterPanel from '@/components/FilterPanel.vue'

export default {
  name: 'tops-layout',
  components: {
    AppHeader,
    AppAside,
    FilterPanel
  },
  computed: {
    isOpenFilterPanel: function () {
      return this.$store.getters['app/isOpenFilterPanel']
    }
  }
}
</script>
